.react-select {
	&-container {
		min-width: 200px;

		&.recipients-select {
			@apply border-b;
		}
	}
	&__value-container {
		@apply font-sans #{!important};
		@apply font-light #{!important};
		@apply px-3 #{!important};
		@apply py-2 #{!important};
		@apply text-gray-700 #{!important};
		max-height: 38px;

		> div:last-child {
			margin: 0 !important;
			padding: 0 !important;
		}
		&.react-select__value-container--is-multi {
			max-height: none;
		}
	}
	&__input {
		input:focus,
		input[type='text']:focus {
			@apply outline-none;
			@apply shadow-none;
			box-shadow: none !important;
		}
	}
	&__menu {
		/*z-index: 10 #{!important}; now in zindex.scss */
	}
	&__control {
		@apply border-gray-300 #{!important};

		.recipients-select & {
			border: 0;
		}

		&--is-focused {
			border-color: transparent !important;
			@apply ring #{!important};
		}
	}
}

.lineBetweenOptions {
	.react-select__option {
		padding-bottom: 0.5rem;
		border-bottom: 1px solid #e7e7e7;
	}
	.react-select__option:last-of-type {
		border-bottom: 0;
	}
}
