.DraftEditor-editorContainer,
.public-DraftEditorPlaceholder-root {
	z-index: 1;
}

.react-select__menu {
	z-index: 10 !important;
}

.slick-prev,
.slick-next {
	z-index: 99;
}

div[id^='tippy-'] {
	z-index: 900 !important;
}

#nprogress .spinner {
	z-index: 1031;
}

.ReactModal__Overlay {
	z-index: 1000;
}

#nprogress .bar {
	z-index: 9999;
}

.rdtPicker {
	z-index: 99999 !important;
}
