.aside-enter {
	/*opacity: 0;*/
	transform: translateX(100%);
}
.aside-enter-active {
	/*opacity: 1;*/
	transform: translateX(0);
	transition: transform 400ms ease-in-out;
}
.aside-exit {
	/*opacity: 1;*/
	transform: translateX(0);
}
.aside-exit-active {
	/*opacity: 0;*/
	transition: transform 400ms ease-in-out;
	transform: translateX(100%);
}

.aside-overlay-enter {
	opacity: 0;
}
.aside-overlay-enter-active {
	opacity: 1;
	transition: opacity 200ms ease-in-out;
}
.aside-overlay-exit {
	opacity: 1;
}
.aside-overlay-exit-active {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}
