@tailwind base;

@tailwind components;

@tailwind utilities;

body {
	@apply antialiased;
}

button,
.button {
	@apply font-sans;
	@apply font-medium;
	@apply py-3;
	@apply px-5;
	@apply rounded-full;
	@apply whitespace-nowrap;
	@apply leading-tight;
}

button:focus {
	@apply outline-none;
	@apply ring;
}

button[disabled] {
	@apply opacity-50;
	@apply cursor-not-allowed;
}

input[type='email'],
input[readonly],
input[type='search'],
input[type='text'],
input[type='url'],
input[type='number'],
input[type='password'],
select,
textarea {
	@apply font-sans;
	@apply font-light;
	@apply appearance-none;
	@apply border;
	@apply rounded;
	@apply px-3;
	@apply text-gray-700;
	padding-top: 0.4375em;
	padding-bottom: 0.4375em;
}

label {
	@apply block;
	@apply font-sans;
	@apply text-gray-700;
	@apply text-sm;
	@apply font-bold;
	@apply mb-2;
}

input[type='email']:focus,
input[type='search']:focus,
input[type='text']:focus,
input[type='number']:focus,
input[type='password']:focus,
textarea:focus {
	@apply outline-none;
	@apply ring;
}

p {
	@apply leading-relaxed;
}

@media screen and (min-width: 1024px) {
	.container {
		@apply max-w-5xl;
	}
}

.margin-ease-in-out {
	transition: 0.2s margin ease-in-out;
}

/* Slider Styling */
span.rc-slider-mark-text {
	@apply text-sm w-16 inline-block text-center;
}

.menu-item-disabled {
	@apply text-gray-300 !important;
}
