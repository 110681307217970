.header {
  nav {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    
    nav {
      max-width: calc(100% - 150px);
    }

    .submenu {
      left: 50%;
      transform: translateX(-50%);
      transform-origin: 0 0;
      min-width: 200px;
      li {
        white-space: nowrap;
      }
    }
    .submenu-parent {
      &:hover {
        > .submenu {
          display: block;
        }
      }
    }
  }
}
