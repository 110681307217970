.index {
	&__blob-wrapper {
		svg {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}
	}
	&__illustration-wrapper {
		svg {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}
	}
}

.inbox-illustration {
	svg {
		width: 100%;
		display: inline-block;
		max-width: 100px;
	}
}

.footer {
	&__illustration-wrapper {
		transform: translateY(-96%) scaleX(-1);
		svg {
			position: absolute;
			height: 100px;
			max-height: 150px;
			width: 100%;
			left: 0;
			bottom: 0;
		}
	}
}

.cc-btn {
	border-radius: 9999px !important;
}

/** Hide revoke button **/
.cc-revoke {
	display: none !important;
}

.account-main {
	transform: translateX(-0.25rem);
	transition: 0.2s transform ease-in-out;
	&.collapse {
		transform: translateX(-12rem);
		min-width: calc(100% - 3.75rem);
	}
}

.challengeSpace,
.challengeNote,
.challengeSpaceNote {
	&__description {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		strong {
			@apply font-semibold;
		}

		h1 {
			@apply text-2xl;
			@apply mb-4;
		}

		h2 {
			@apply text-xl;
			@apply mb-3;
		}

		h3 {
			@apply text-lg;
			@apply mb-3;
		}

		h4,
		h5,
		h6 {
			@apply text-base;
			@apply mb-2;
		}

		ol {
			@apply list-decimal;
			@apply mb-2;
			@apply pl-8;
		}

		p {
			@apply mb-3;
		}

		a {
			@apply text-blue-500;
		}

		a:hover {
			@apply text-blue-600;
			@apply underline;
		}

		ul {
			@apply list-disc;
			@apply mb-2;
			@apply pl-8;
		}
	}
}

//Setting default color for all mailto a tags
a[href^='mailto:'],
a[href^='mailto:'] > * {
	color: rgb(59, 130, 246) !important;
}

.glowing-button {
	/*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
	animation: pulse 2.5s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(91, 33, 182, 0.35);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(91, 33, 182, 0);
	}

	100% {
	}
}

/* mail chimp modal bg */
.mc-layout__modalContent {
	background: #fff;
}
