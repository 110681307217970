.ReactModal {
	&__Content {
		padding: 0 !important;
		max-width: 650px;
		margin: 0 auto;
		max-height: calc(100% - 80px);
		bottom: auto !important;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&#unsplash,
		&#teamFormationInfo {
			max-width: 100%;
		}
	}
	&__Overlay {
		/*z-index: 1000; now in zindex.scss */
		background-color: rgba(0, 0, 0, 0.4) !important;
	}
	&__Wrapper {
		overflow-y: scroll;
	}
}
